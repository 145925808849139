import { ROLES } from "constants";
import { lazy } from "react";
import LoginPage from "../components/pages/login/Login";
import { ROUTE_PATHS } from "./routePaths";
import * as RouteScreens from "./routes";

export const Layout = lazy(() => import("../components/pages/Layout"));

const SUPER_ADMIN_ROUTES = [
  {
    path: ROUTE_PATHS.DASHBOARD,
    element: RouteScreens.DashboardReport,
  },
  {
    path: ROUTE_PATHS.USERS_LIST,
    element: RouteScreens.UsersList,
  },
  {
    path: ROUTE_PATHS.USERS_FORM,
    element: RouteScreens.UsersForm,
  },
  {
    path: ROUTE_PATHS.CUSTOMER_LIST,
    element: RouteScreens.CustomersList,
  },
  {
    path: ROUTE_PATHS.CUSTOMER_FORM,
    element: RouteScreens.CustomersForm,
  },
  {
    path: ROUTE_PATHS.LEAD_STATUS_LIST,
    element: RouteScreens.LeadStatusList,
  },
  {
    path: ROUTE_PATHS.LEAD_STATUS_FORM,
    element: RouteScreens.LeadStatusForm,
  },
  {
    path: ROUTE_PATHS.STAGES_LIST,
    element: RouteScreens.StagesList,
  },
  {
    path: ROUTE_PATHS.STAGES_FORM,
    element: RouteScreens.StagesForm,
  },
  {
    path: ROUTE_PATHS.ACTIONS_LIST,
    element: RouteScreens.ActionsList,
  },
  {
    path: ROUTE_PATHS.ACTIONS_FORM,
    element: RouteScreens.ActionsForm,
  },
  {
    path: ROUTE_PATHS.ACTION_RESULTS_LIST,
    element: RouteScreens.ResultList,
  },
  {
    path: ROUTE_PATHS.ACTION_RESULTS_FORM,
    element: RouteScreens.ResultForm,
  },
  {
    path: ROUTE_PATHS.PARAMETER,
    element: RouteScreens.Parameter,
    children: [
      { path: ROUTE_PATHS.KNOWN_SOURCE, element: RouteScreens.ParameterForm },
      { path: ROUTE_PATHS.LEAD_SOURCE, element: RouteScreens.ParameterForm },
      {
        path: ROUTE_PATHS.COUNTRY_SOURCE,
        element: RouteScreens.CountryParameterForm,
      },
    ],
  },
  {
    path: ROUTE_PATHS.GLOBAL_PARAMETER,
    element: RouteScreens.Parameter,
    children: [
      {
        path: ROUTE_PATHS.SEND_OPTIONS,
        element: RouteScreens.ParameterForm,
      },
    ],
  },
  {
    path: ROUTE_PATHS.MESSAGE_MASTER_LIST,
    element: RouteScreens.MessageList,
  },
  {
    path: ROUTE_PATHS.MESSAGE_MASTER_FORM,
    element: RouteScreens.MessageForm,
  },
  {
    path: ROUTE_PATHS.CAMPAIGN_LIST,
    element: RouteScreens.CampaignList,
  },
  {
    path: ROUTE_PATHS.CAMPAIGN_FORM,
    element: RouteScreens.CampaignForm,
  },
  {
    path: ROUTE_PATHS.PIPELINE_MASTER_LIST,
    element: RouteScreens.PipelineMasterList,
  },
  {
    path: ROUTE_PATHS.PIPELINE_MASTER_FORM,
    element: RouteScreens.PipelineMasterForm,
  },
  {
    path: ROUTE_PATHS.PIPELINE_CONFIG_LIST,
    element: RouteScreens.PipeLineConfigList,
  },
  {
    path: ROUTE_PATHS.PIPELINE_CONFIG_FORM,
    element: RouteScreens.PipeLineConfigForm,
  },
  {
    path: ROUTE_PATHS.PIPELINE_MAPPING_LIST,
    element: RouteScreens.PipelineMappingList,
  },
  {
    path: ROUTE_PATHS.PIPELINE_MAPPING_FORM,
    element: RouteScreens.PipelineMappingForm,
  },
  {
    path: ROUTE_PATHS.LEADS_LIST,
    element: RouteScreens.leadsList,
  },
  {
    path: ROUTE_PATHS.LEADS_FORM,
    element: RouteScreens.leadsForm,
  },
  {
    path: ROUTE_PATHS.TRACK_LEADS,
    element: RouteScreens.TrackLeadsList,
  },
  {
    path: ROUTE_PATHS.TRACK_LEADS_FORM,
    element: RouteScreens.TrackLeadsForm,
  },
  {
    path: ROUTE_PATHS.IMPORT,
    element: RouteScreens.LeadBulkUpload,
  },
  { path: ROUTE_PATHS.PROFILE, element: RouteScreens.Profile },
  { path: ROUTE_PATHS.EDIT_PROFILE, element: RouteScreens.EditProfile },
  { path: ROUTE_PATHS.CHANGE_PASSWORD, element: RouteScreens.ChangePassword },
  {
    path: ROUTE_PATHS.VERSION_CONTROL_LIST,
    element: RouteScreens.VersionControlList,
  },
  {
    path: ROUTE_PATHS.VERSION_CONTROL_FORM,
    element: RouteScreens.VersionControlForm,
  },
];

const routes = {
  [ROLES.SUPER_ADMIN]: SUPER_ADMIN_ROUTES,
  [ROLES.ADMIN]: SUPER_ADMIN_ROUTES,
};

export const loginRoutes = [
  {
    path: ROUTE_PATHS.LOGIN,
    element: <LoginPage />,
    exact: true,
  },
];

export default routes;
