import { lazy } from "react";

export const UsersForm = lazy(() => import("../components/pages/users/Form"));

export const UsersList = lazy(() => import("../components/pages/users/List"));

export const CustomersList = lazy(() =>
  import("../components/pages/customer/List"),
);

export const CustomersForm = lazy(() =>
  import("../components/pages/customer/Form"),
);

export const LeadStatusList = lazy(() =>
  import("../components/pages/leadStatus/List"),
);

export const LeadStatusForm = lazy(() =>
  import("../components/pages/leadStatus/Form"),
);

export const StagesList = lazy(() => import("../components/pages/stages/List"));

export const StagesForm = lazy(() => import("../components/pages/stages/Form"));

export const ActionsList = lazy(() =>
  import("../components/pages/actions/List"),
);

export const ActionsForm = lazy(() =>
  import("../components/pages/actions/Form"),
);

export const ResultList = lazy(() => import("../components/pages/result/List"));

export const ResultForm = lazy(() => import("../components/pages/result/Form"));

export const Parameter = lazy(() =>
  import("../components/pages/parameter/Parameter"),
);

export const ParameterForm = lazy(() =>
  import("../components/pages/parameter/ParameterForm"),
);

export const CountryParameterForm = lazy(() =>
  import("../components/pages/parameter/CountryParameterForm"),
);
export const MessageList = lazy(() =>
  import("../components/pages/messages/List"),
);

export const MessageForm = lazy(() =>
  import("../components/pages/messages/Form"),
);

export const CampaignList = lazy(() =>
  import("../components/pages/campaign/List"),
);

export const CampaignForm = lazy(() =>
  import("../components/pages/campaign/Form"),
);

export const PipelineMasterList = lazy(() =>
  import("../components/pages/pipeline/masters/List"),
);
export const PipelineMasterForm = lazy(() =>
  import("../components/pages/pipeline/masters/Form"),
);

export const PipeLineConfigList = lazy(() =>
  import("../components/pages/pipeline/config/List"),
);
export const PipeLineConfigForm = lazy(() =>
  import("../components/pages/pipeline/config/Redesign"),
);

export const PipelineMappingList = lazy(() =>
  import("../components/pages/pipeline/mapping/List"),
);
export const PipelineMappingForm = lazy(() =>
  import("../components/pages/pipeline/mapping/Form"),
);

export const leadsList = lazy(() => import("../components/pages/lead/List"));
export const leadsForm = lazy(() => import("../components/pages/lead/Form"));
export const LeadBulkUpload = lazy(() =>
  import("../components/pages/lead/BulkUpload"),
);

export const TrackLeadsList = lazy(() =>
  import("../components/pages/trackLeads/List"),
);
export const TrackLeadsForm = lazy(() =>
  import("../components/pages/trackLeads/Form"),
);

export const Profile = lazy(() =>
  import("../components/pages/profile/Profile"),
);
export const EditProfile = lazy(() =>
  import("../components/pages/profile/EditProfile"),
);
export const ChangePassword = lazy(() =>
  import("../components/pages/profile/ChangePassword"),
);

export const VersionControlList = lazy(() =>
  import("../components/pages/versionControl/List"),
);

export const VersionControlForm = lazy(() =>
  import("../components/pages/versionControl/Form"),
);

export const DashboardReport = lazy(() =>
  import("../components/pages/dashboard/Report"),
);
