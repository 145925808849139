export const ROUTE_PATHS = {
  LOGIN: "/login",
  SET_PASSWORD: "/setPassword",
  RESET_PASSWORD: "/resetPassword",
  NOT_FOUND: "/*",
  LAYOUT: "/",

  //USERS
  PROFILE: "/profile",
  EDIT_PROFILE: "/updateProfile",
  CHANGE_PASSWORD: "/changePassword",
  USERS_LIST: "/users/list",
  USERS_FORM: "/users/form",
  MY_ORGANIZATION: "/myOrganization",
  MY_BRANCH: "/myBranch",
  ROLE_AND_ACCESS_LIST: "/roleAndAccess/list",
  ROLE_AND_ACCESS_FORM: "/roleAndAccess/form",

  DASHBOARD: "/dashboard",

  VERSION_CONTROL_LIST: "/versionControl/list",
  VERSION_CONTROL_FORM: "/versionControl/form",

  CUSTOMER_LIST: "/customers/list",
  CUSTOMER_FORM: "/customers/form",

  LEAD_STATUS_LIST: "/master/leadStatus/list",
  LEAD_STATUS_FORM: "/master/leadStatus/form",

  MY_USERS_LIST: "/myUsers/list",
  MY_USERS_FORM: "/myUsers/form",

  LEADS_LIST: "/leads/list",
  LEADS_FORM: "/leads/form",
  IMPORT: "/leads/import",

  STAGES_LIST: "/master/stages/list",
  STAGES_FORM: "/master/stages/form",

  ACTIONS_LIST: "/master/actions/list",
  ACTIONS_FORM: "/master/actions/form",

  ACTION_RESULTS_LIST: "/master/actionResult/list",
  ACTION_RESULTS_FORM: "/master/actionResult/form",

  PIPELINE_MASTER_LIST: "/pipeline/master/list",
  PIPELINE_MASTER_FORM: "/pipeline/master/form",

  PIPELINE_CONFIG_LIST: "/pipeline/config/list",
  PIPELINE_CONFIG_FORM: "/pipeline/config/form",

  PIPELINE_MAPPING_LIST: "/pipeline/mapping/list",
  PIPELINE_MAPPING_FORM: "/pipeline/mapping/form",

  //PARAMETER
  PARAMETER: "/parameter",
  GLOBAL_PARAMETER: "/globalParameter",

  KNOWN_SOURCE: "/parameter/knownSource",
  LEAD_SOURCE: "/parameter/leadSource",
  COUNTRY_SOURCE: "/parameter/countrySource",
  SEND_OPTIONS: "/globalParameter/sendOptions",

  MESSAGE_MASTER_FORM: "/master/messages/form",
  MESSAGE_MASTER_LIST: "/master/messages/list",

  CAMPAIGN_LIST: "/campaigns/list",
  CAMPAIGN_FORM: "/campaigns/form",

  TRACK_LEADS: "/trackLeads/list",
  TRACK_LEADS_FORM: "/trackLeads/form",
};
