import axios from "axios";

import { setIsLoading } from "../redux/slice";
import store from "../redux/store";
import { ROUTE_PATHS } from "../routes/routePaths";
import { apiConfig } from "../utils/apiConfig";
import { getCookie, removeCookie, setCookie } from "../utils/cookies";
import { getApiServices } from "./api";
import { apiPaths } from "./apiPaths";

export const appApi = axios.create({
  // baseURL: "https://api.crm.softtechashram.com",
  // baseURL: "http://localhost:3000",
  baseURL:
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_DEV_MODE
      : process.env.REACT_APP_PRO_MODE,
});

appApi.interceptors.request.use(
  (config) => {
    config.headers["authorization"] = `Bearer ${getCookie("token")}`;
    store.dispatch(setIsLoading(true));
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

appApi.interceptors.response.use(
  (response) => {
    store.dispatch(setIsLoading(false));
    return response;
  },
  async (error) => {
    const { isTokenExpires, currentTime, isRefreshTokenExpires } = apiConfig();
    if (isTokenExpires < currentTime && isRefreshTokenExpires < currentTime) {
      removeCookie("token");
      removeCookie("refreshToken");
      removeCookie("id");
      store.dispatch(setIsLoading(false));
      window.location.href = ROUTE_PATHS.HOME;
      return;
    }
    const originalRequest = error.config;
    let retry = false;
    if (isTokenExpires < currentTime && !retry) {
      try {
        setCookie("token", getCookie("refreshToken"));
        const { data } = await getApiServices(apiPaths.REFRESH_TOKEN);
        retry = true;
        originalRequest.headers[
          "authorization"
        ] = `Bearer ${data?.tokens?.token}`;
        setCookie("token", data?.tokens?.token);
        setCookie("refreshToken", data?.tokens?.refreshToken);
      } catch (err) {
        window.location.href = ROUTE_PATHS.HOME;
        // <Navigate to={ROUTE_PATHS.HOME} />;
        store.dispatch(setIsLoading(false));
        retry = true;
      }

      return appApi(originalRequest);
    } else if (error.status === 401) {
      removeCookie("token");
      removeCookie("refreshToken");
      removeCookie("id");

      window.location.href = ROUTE_PATHS.HOME;
      store.dispatch(setIsLoading(false));
      retry = false;
      return Promise.reject(error);
    } else {
      // removeCookie("token");
      // removeCookie("refreshToken");
      // removeCookie("id");

      // window.location.href = ROUTE_PATHS.HOME;
      store.dispatch(setIsLoading(false));
      retry = false;
      return Promise.reject(error);
    }
    // store.dispatch(setIsLoading(false));
    // return Promise.reject(error);
  },
);
